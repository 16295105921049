// src/components/Loader.js
import React from 'react';
import styled from 'styled-components';

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1C1C27; /* Solid background color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
`;

const LoaderWrapper = styled.div`
  width: 3em;
  height: 3em;
  cursor: not-allowed;
  border-radius: 50%;
  border: 2px solid #444;
  box-shadow: 
    -10px -10px 10px #6359f8, 
    0px -10px 10px 0px #9c32e2, 
    10px -10px 10px #f36896, 
    10px 0 10px #ff0b0b, 
    10px 10px 10px 0px #ff5500, 
    0 10px 10px 0px #ff9500, 
    -10px 10px 10px 0px #ffb700;
  animation: rot55 0.7s linear infinite;

  .spinnerin {
    border: 2px solid #444;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes rot55 {
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoaderBackground = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%),
              linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Loader = () => {
    return (
        <LoaderOverlay>
            <LoaderBackground />
            <LoaderWrapper />
        </LoaderOverlay>
    );
};

export default Loader;
