export const darkTheme = {
    bg: "#1C1C27",
    bgLight: "#1C1E27",
    primary: "#854CE6",
    text_primary: "#F2F3F4",
    text_secondary: "#b1b2b3",
    card: "#171721",
    card_light: '#191924',
    button: "#854CE6",
    white: "#FFFFFF",
    black: "#000000",
}

export const lightTheme = {
    bg: "#FFFFFF",
    bgLight: "#f0f0f0",
    primary: "#be1adb",
    text_primary: "#111111",
    text_secondary: "#48494a",
    card: "#FFFFFF",
    button: "#5c5b5b",
}

// export const darkTheme = {
//     bg: "#0D0D0D", // Dark background
//     bgLight: "#1A1A1A", // Slightly lighter for contrast
//     primary: "#FF6B6B", // Bold primary color (Coral)
//     text_primary: "#EAEAEA", // Light text for readability
//     text_secondary: "#A5A5A5", // Softer secondary text
//     card: "#242424", // Dark card background
//     card_light: '#2A2A2A', // Light card variant
//     button: "#FF6B6B", // Same as primary for consistency
//     white: "#FFFFFF", // Pure white for highlights
//     black: "#000000", // Pure black
// }

// export const lightTheme = {
//     bg: "#F7F7F7", // Soft light background
//     bgLight: "#FFFFFF", // Bright white for sections
//     primary: "#4A90E2", // Bright blue
//     text_primary: "#2C2C2C", // Dark gray text
//     text_secondary: "#707070", // Medium gray for less important text
//     card: "#FFFFFF", // White for cards
//     button: "#FF6B6B", // Coral color for buttons (consistent with dark theme)
// }